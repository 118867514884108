import { useCallback, useEffect, useRef, useState } from 'react';
import { useLatest, useInViewport } from 'ahooks';

interface UseImpressionOpts {
    /** Fired when the impression requirements are met */
    onImpression(url: URL): Promise<void>;
    ref: React.MutableRefObject<Element | null>;
    disable?: boolean;
    // impress by version. By default, we will fire only once for each hook instance.
    // If this is set to true, we will fire once for each version.
    // This is for the impression event on the MegaMenu FeaturedTile.
    // We share the same hook instance across directories on MegaMenu, and we want to fire the impression event once for each directory.
    impressByVersion?: boolean;
    version?: number | string | null;
}

/**
 * Impression hook follows the MRC standard that is followed by google.
 *
 * Aside from general requirements (That are not handled here.. not yet, at least),
 * the pixel, and time, requirements are 50% and continous 1 second, respectively, is followed here.
 *
 */
export const TIME_REQUIREMENT = 1000; // One second
export const PIXEL_REQUIREMENT = 0.5; // 50% of the item must be in view

const useImpression = (
    opts: UseImpressionOpts
): {
    reset: () => void;
} => {
    const optsRef = useLatest(opts);
    const versionRef = useLatest(optsRef.current.version ?? null);
    const [hasFiredImpression, setHasFiredImpression] = useState(false);
    const previousVersionsRef = useRef<Record<string, boolean>>({});

    const [inView, ratio] = useInViewport(optsRef.current.ref, {
        threshold: [0, 0.25, 0.5, 0.75, 1],
    });

    const visibilityChangedTimer = useRef<ReturnType<typeof setTimeout> | null>(
        null
    );
    const clearCurrentTimeout = () => {
        visibilityChangedTimer.current &&
            clearTimeout(visibilityChangedTimer.current);
    };

    const impressByVersion = optsRef.current.impressByVersion || false;

    const sendImpression = useCallback(
        async (url: URL) => {
            setHasFiredImpression(true);
            if (impressByVersion) {
                previousVersionsRef.current[versionRef?.current || ''] = true;
            }
            await optsRef.current.onImpression(url);
        },
        [impressByVersion, optsRef, versionRef]
    );

    useEffect(() => {
        if (
            opts.disable ||
            (!impressByVersion && hasFiredImpression) ||
            (impressByVersion &&
                (!versionRef?.current ||
                    previousVersionsRef.current[versionRef?.current]))
        ) {
            clearCurrentTimeout();
            return;
        }
        clearCurrentTimeout();

        if (inView && ratio && ratio > PIXEL_REQUIREMENT) {
            // Capture it and store it
            // otherwise the timeout might fire when the user is on a different page.
            const currentLocation = new URL(window.location.href);
            visibilityChangedTimer.current = setTimeout(() => {
                void sendImpression(currentLocation);
            }, TIME_REQUIREMENT);
        }

        return () => {
            clearCurrentTimeout();
        };
    }, [
        hasFiredImpression,
        inView,
        opts.disable,
        ratio,
        sendImpression,
        impressByVersion,
        versionRef,
    ]);

    const reset = useCallback(() => {
        setHasFiredImpression(false);
    }, []);

    return { reset };
};

export default useImpression;
