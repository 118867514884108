import { useLatest } from 'ahooks';
import useImpression from './useImpression';
import { LogImpressionProps, useTracker } from '..';
import { ARCHIPRO_IMPRESSION_TRACKER } from '../../trackers';

interface Props {
    data?: LogImpressionProps;
    ref: React.MutableRefObject<Element | null>;
    impressByVersion?: boolean;
    version?: number | string | null;
}
export const useLogImpression = ({
    data,
    ref,
    impressByVersion,
    version,
}: Props) => {
    const trackerData = useLatest<LogImpressionProps | undefined>(data);
    const tracker = useTracker();

    useImpression({
        ref,
        disable: !trackerData.current,
        impressByVersion,
        version,
        onImpression: async () => {
            const impression = trackerData.current;
            if (
                !impression ||
                impression.professionalID <= 0 ||
                impression.itemID <= 0
            ) {
                return;
            }

            await tracker.log('Impression', {
                url: new URL(window.location.href),
                data: {
                    ID: impression.itemID,
                    type: impression.type,
                    ProfessionalID: impression.professionalID,
                },
                targetTracker: ARCHIPRO_IMPRESSION_TRACKER,
            });
        },
    });
};
